import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

const Section = dynamic(() => import('sensortower-components/src/base-components/Section'));
const Card = dynamic(() => import('sensortower-components/src/base-components/Card'));
const CodeSnippet = dynamic(() => import('./components/CodeSnippet/CodeSnippet'));
const Collection = dynamic(() => import('sensortower-components/src/base-components/Collection'));
const CollectionCarousel = dynamic(() => import('sensortower-components/src/base-components/CollectionCarousel'));
const CollectionNavigationBar = dynamic(() => import('./components/CollectionNavigationBar/CollectionNavigationBar'));
const NavigationItem = dynamic(() => import('./components/NavigationItem/NavigationItem'));
const NavigationItemGroup = dynamic(() => import('./components/NavigationItemGroup/NavigationItemGroup'));
const NavigationItemSubGroup = dynamic(() => import('./components/NavigationItemSubGroup/NavigationItemSubGroup'));
const NavigationItemLink = dynamic(() => import('./components/NavigationItemLink/NavigationItemLink'));
const Link = dynamic(() => import('./components/Link/Link'));
const Media = dynamic(() => import('./components/Media/Media'));
const MediaVidyard = dynamic(() => import('./components/MediaVidyard'));
const Blog = dynamic(() => import('./pages/Blog/Blog'));
const PageGeneral = dynamic(() => import('./pages/PageGeneral/PageGeneral'));
const Resource = dynamic(() => import('./pages/Resource/Resource'));
const SectionLatestArticles = dynamic(() => import('./components/SectionLatestArticles/SectionLatestArticles'));
const FormMarketoEmbed = dynamic(() => import('./components/LeadForm/FormMarketoEmbed/FormMarketoEmbed'));
const FormInfogramEmbed = dynamic(() => import('./components/LeadForm/FormInfogramEmbed/FormInfogramEmbed'));
const NewsletterForm = dynamic(() => import('./components/NewsletterForm/NewsletterForm'));
const SectionPricingTable = dynamic(() => import('./components/SectionPricingTable/SectionPricingTable'));
const Footer = dynamic(() => import('./components/Footer/Footer'));
const Lever = dynamic(() => import('./components/Lever/Lever'));
const CollectionImageGrid = dynamic(() => import('./components/CollectionImageGrid/CollectionImageGrid'));
const SectionTabbedSector = dynamic(() => import('./components/SectionTabbedSector/SectionTabbedSector'));
const ModuleIntegrationIFrame = dynamic(() => import('./components/ModuleIntegrationIFrame/ModuleIntegrationIFrame'));
const CommonResource = dynamic(() => import('./components/CommonResource/CommonResource'));

const Header = dynamic(() => import('sensortower-components/src/base-components/Header'));
const Hero = dynamic(() => import('sensortower-components/src/base-components/Hero'));
const Text = dynamic(() => import('./components/Text/Text'));

const contentMapping: {
  [contentType: string]: ComponentType<any>;
} = {
  Header,
  Section,
  Collection,
  'Collection:carousel': CollectionCarousel,
  'Collection:carousel-large': CollectionCarousel,
  'Collection:navigation-bar': CollectionNavigationBar,
  Card,
  Text,
  Media,
  'Media:vidyard': MediaVidyard,
  Link,
  NavigationItem,
  'Quote': Card,
  'NavigationItem:group': NavigationItemGroup,
  'NavigationItem:subgroup': NavigationItemSubGroup,
  'NavigationItem:link': NavigationItemLink,
  'NavigationItem:login': NavigationItemLink,
  Hero,
  'Page': PageGeneral,
  'LeadForm:marketo-default': FormMarketoEmbed,
  'LeadForm:marketo-infogram': FormInfogramEmbed,
  'LeadForm:marketo-newsletter': NewsletterForm,
  Blog,
  Resource,
  'Section:latest-articles': SectionLatestArticles,
  'Section:pricing-table': SectionPricingTable,
  'Footer': Footer,
  'ModuleIntegration:lever': Lever,
  'Collection:image-grid': CollectionImageGrid,
  'Collection:2x2-image-grid': CollectionImageGrid,
  'Section:tabbed-sector': SectionTabbedSector,
  'ModuleIntegration:iframe': ModuleIntegrationIFrame,
  'CommonResource': CommonResource,
  CodeSnippet
};

export default contentMapping;
